import React from "react";
import "./components/style.css";

// component
import Mail from "./components/mail";
import Foods from "./components/Foods";

export default function App() {
  return (
    <>
      <Mail />
      <Foods />
    </>
  );
}
